export const filterTokenCrypto = ({ cryptoCurrencies, popularTokens, cryptoSearch }) => {
  let popularBtcCurrency = cryptoCurrencies?.filter((token) => token.currencyCode === popularTokens?.[0])
  let popularEthCurrency = cryptoCurrencies?.filter((token) => token.currencyCode === popularTokens?.[1])
  let popularCurrencies = popularBtcCurrency.concat(popularEthCurrency)
  let popularExactCurrencies = popularCurrencies.filter((crypto) => {
    const displayName = crypto?.name?.toLowerCase()
    const currencyCode = crypto?.currencyCode?.toLowerCase()
    if (displayName === cryptoSearch?.toLowerCase() || currencyCode === cryptoSearch?.toLowerCase()) {
      return crypto
    }
  })
  let popularNonExactCurrencies = popularCurrencies.filter((crypto) => {
    const excludeCrypto = popularExactCurrencies.find((token) => token.currencyCode === crypto?.currencyCode)
    if (!excludeCrypto) {
      const displayName = crypto?.name?.toLowerCase().includes(cryptoSearch?.toLowerCase())
      const currencyCode = crypto?.currencyCode?.toLowerCase().includes(cryptoSearch?.toLowerCase())
      if (displayName || currencyCode) {
        return crypto
      }
    }
  })
  return popularExactCurrencies.concat(popularNonExactCurrencies)
}

export default filterTokenCrypto
