/* eslint-disable complexity */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDestinationCurrencyCode, setDestinationCurrencyCodeLocked, setSelectedDestination } from 'store/slices/wizard'
import styled from 'styled-components'
import providerFiatCurrency from './services/providerFiatCurrency'
import { Col, Input, Row, Skeleton } from 'antd'
import DrawerDropdown from './components/drawerDropdown'
import providerCryptoCurrency from './services/providerCryptoCurrency'
import providerCurrencies from './services/providerCurrencies'
import sortCryptoCurrencies from './services/sortCryptoCurrencies'
import { selectTheme } from 'store/slices/theme'
import _ from 'lodash'
import { symbolCurrencies, turkeyCurrencySymbol } from 'utils/constants'
import mixPanel from './services/mixpanel'
// eslint-disable-next-line no-unused-vars
import WizardQuoteWrapper from './wizardQuotesWrapper'
import WizardQuoteWrapperNew from './wizardQuotesWrapperNew'
import { getCurrencySymbol } from './components/utils'


const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  border-radius: 8px 8px 0 0;
  margin: 10px 0px 0px 0px;
  background: ${({ theme }) => theme.colors.cardBackground};
  height: ${({ height }) => height};
  padding: ${({ padding }) => padding};
`

const AmountSection = styled(Row)`
  // padding: ${({ padding }) => padding};
`

const DestinationSkeleton = styled(Skeleton)`
  .ant-skeleton-content {
    display: flex;
    align-items: center;
  }
  .ant-skeleton-title {
    width: 95% !important;
  }
`

const DestinationCurrencyField = styled.div`
  background: ${({ theme }) => theme.colors.currencyCardBackground};
  border-radius: 9px;
  padding: 0px 10px;
  border: 1px solid ${({ theme }) => theme.colors.currencyCardBorder};
  color: ${({ theme }) => theme.colors.currencyCardColor};
  // width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  margin-top: 22px;
`

const InputWrapper = styled.div`
  margin-top: 0px;
`

const CardInput = styled.div``

const CryptoCol = styled(Col)`
  display: flex;
  align-items: center;
`

const Label = styled.span`
  margin-top: -5px;
  font-size: ${({ fontSize }) => fontSize > 0 ? '14px' : '14px'};
  color: ${({ theme }) => theme.colors.labelColor};
`

const SourceSkeleton = styled(Skeleton)`
  .ant-skeleton-content {
    display: flex;
    align-items: center;
  }
  .ant-skeleton-title {
    width: 95% !important;
  }
`

const SearchField = styled(Input)`
  height: 38px;
  line-height: 0;
  font-size: 38px;
  font-weight: 600;
  width: ${({ width }) => width === 1 ? 90 : (width === 2 ? 85 : 100)}%;
  color: ${({ theme }) => theme.colors.drawerSearchInput} !important;
  padding: 0px !important;

  .ant-input{
    color: ${({ theme }) => theme.colors.drawerSearchInput} !important;
  }
`

const AmountWrapper = styled.div`
  margin-top: 5px;
  height: 38px;
  width: 105%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  div {
    flex: 1 1;
  }
`


const ExchangeCol = styled.div`
  text-align: left;
  background-color: ${({ theme }) => theme.colors.currencyCardBackground} !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  padding: 12px;
  color: ${({ theme }) => theme.colors.labelColorSecondary} !important;
  width: 100%;
  border-radius: 0 0 8px 8px;
  border: 1px solid ${({ theme }) => theme.colors.currencyCardBorder} ;
  border-top: 0;
`
 const QuotesWrapper=styled.div`
 display: flex;
  flex-direction: row; /* Adjust this as needed (row, column, etc.) */
  justify-content: space-between ; /* Adjust this as needed (center, space-between, etc.) */
  align-items: flex-start; /* Adjust this as needed (center, flex-end, etc.) */
  text-align: left;
  // background-color: ${({ theme }) => theme.colors.currencyCardBackground} !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* padding: 12px; */
  color: ${({ theme }) => theme.colors.labelColorSecondary} !important;
  width: 100%;
  border-radius: 0 0 8px 8px;
  border: 1px solid ${({ theme }) => theme.colors.currencyCardBorder};
  border-top: 0;
  margin-bottom: 14px;
`

const CurrencyCol = styled(Col)`
  position: inherit;
  display: flex;
  justify-content: right;
  align-items: center;
`

const WizardDestinationWrapper = ({serviceProvider, accountFiatCurrency, accountCryptoCurrency, transactionFlow, isInitialLoad, sourceErrorMsg, topServiceProviderList, onModalError, isWalletAddressEntered, resetWalletAddressEntered, lastCryptoCurrency, lastCryptoCurrencyState, isCountryChange, userWalletAddress}) => {
  const dispatch = useDispatch()
  const theme = useSelector(selectTheme)
  const isDestinationInputError = false
  const { urlParams, countryCode, paymentMethod, buySellFlow, destinationCurrencyAmount, exchangeRateAmount, sourceCurrencyCode, destinationCurrencyCode, selectedQuotes, lockDestinationCurrencyCode } = useSelector((state) => state.wizard)
  const [destinationCurrencyData, setDestinationCurrencyData] = useState([])
  const [selectedDestinationCurrency, setSelectedDestinationCurrency] = useState({})
  const [cryptoChain, setCryptoChain] = useState([])
  const [destinationAmount, setDestinationAmount] = useState(0)
  const [ exchangeRate, setExchangeRate ] = useState(0)
  const popularTokens = ['BTC', 'ETH']

  useEffect(() => {
    if (!isInitialLoad) {
      setSelectedDestinationCurrency({})
    }
  }, [transactionFlow, isInitialLoad])

  useEffect(() => {
    const initDestinationCurrency = async () => {
      if (buySellFlow === 'BUY') {
        const result = accountCryptoCurrency
        if (result?.length > 0) {
          const cryptoCurrencies = _.uniqBy(sortCryptoCurrencies(result, popularTokens, ''), 'currencyCode')
          setDestinationCurrencyData(cryptoCurrencies)
          let cryptoChains = _.uniqBy(cryptoCurrencies.map((crypto) => crypto.chainName))
          cryptoChains = cryptoChains.map((crypto) => {
            return {
              value: crypto,
              label: crypto,
              className: theme.name === 'lightMode' && 'text-white'
            }
          })
          cryptoChains.unshift({ value: '', label: 'All Chains', className: 'text-bold' })
          setCryptoChain(cryptoChains)
          const lastCryptoCurrencyFound = (lastCryptoCurrency?.currencyCode) ? result?.find((currency) => currency?.currencyCode === lastCryptoCurrency?.currencyCode) : {}
          const data = providerCryptoCurrency(urlParams, result, '')
          if (data?.final && !destinationCurrencyCode) {
            dispatch(setDestinationCurrencyCodeLocked(!!data?.initial))
            dispatch(setDestinationCurrencyCode(lastCryptoCurrencyFound?.currencyCode ?? data.final ?? 'BTC'))
            const initial = providerCurrencies(urlParams, buySellFlow, result, data.final, 'crypto', lastCryptoCurrency)
            const lastCryptoFound = lastCryptoCurrencyFound?.currencyCode ? lastCryptoCurrencyFound : initial.selectedCrypto
            setSelectedDestinationCurrency(lastCryptoFound)
            lastCryptoCurrencyState(lastCryptoFound)
            dispatch(setSelectedDestination(lastCryptoFound))
          }
          if (destinationCurrencyCode && !selectedDestinationCurrency?.currencyCode) {
            const initial = providerCurrencies(urlParams, buySellFlow, result, destinationCurrencyCode, 'crypto', lastCryptoCurrency)
            const lastCryptoFound = lastCryptoCurrencyFound?.currencyCode ? lastCryptoCurrencyFound : initial.selectedCrypto
            setSelectedDestinationCurrency(lastCryptoFound)
            lastCryptoCurrencyState(lastCryptoFound)
            dispatch(setSelectedDestination(lastCryptoFound))
          }
        }
      } else {
        const result = accountFiatCurrency
        setDestinationCurrencyData(result?.length > 0 ? result : [])
        if (result?.length > 0) {
          const data = providerFiatCurrency(urlParams, result, countryCode, buySellFlow)
          if (data?.final && !destinationCurrencyCode) {
            dispatch(setDestinationCurrencyCodeLocked(!!data.initial))
            dispatch(setDestinationCurrencyCode(data.final))
            const initial = providerCurrencies(urlParams, buySellFlow, result, data?.final, 'fiat')
            setSelectedDestinationCurrency(initial.selectedFiat)
          }
          if (destinationCurrencyCode && !selectedDestinationCurrency?.currencyCode) {
            const initial = providerCurrencies(urlParams, buySellFlow, result, destinationCurrencyCode, 'fiat')
            setSelectedDestinationCurrency(initial.selectedFiat)
          }
        }
      }
    }
    if (countryCode && (paymentMethod?.paymentMethod)) {
      initDestinationCurrency()
    }
  }, [countryCode, paymentMethod])

  useEffect(() => {
    if (!destinationCurrencyCode || destinationCurrencyCode === '') {
      setSelectedDestinationCurrency({})
    }
  }, [destinationCurrencyCode])

  useEffect(() => {
    setDestinationAmount(destinationCurrencyAmount)
    if (selectedQuotes.length > 0) {
      const quote = selectedQuotes[0]
      if (quote?.destinationAmount > 0) {
        setDestinationAmount(quote.destinationAmount)
        setExchangeRate(quote.exchangeRate)
      }
    }
  }, [destinationCurrencyAmount, selectedQuotes])

  const cryptoPrecision = (cryptoAmount, cryptoExchange, destinationCurrencyCode) => {
    if (buySellFlow === 'BUY') {
      const precision = Math.min(7, Math.max(2, Math.ceil(Math.log10(cryptoExchange))))
      return cryptoAmount.toFixed(precision)
    }
    let numberFormat
    try {
      numberFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: destinationCurrencyCode, currencyDisplay: 'code' }).format(cryptoAmount)
      if (destinationCurrencyCode === 'TRY') {
        numberFormat = numberFormat.replace(destinationCurrencyCode, turkeyCurrencySymbol)
      }
    } catch (e) {
      numberFormat = `${cryptoAmount.toFixed(5)} ${destinationCurrencyCode}`
    }
    numberFormat = numberFormat.replace(destinationCurrencyCode, '').replace(',', '').trim()
    const symbol = getCurrencySymbol(destinationCurrencyCode)
    if (symbol) {
      return `${symbol} ${numberFormat}`
    }
    return numberFormat
  }

  return (
    <>
      <Container
        padding={'15px 10px 15px 10px'}
        height={symbolCurrencies?.includes(sourceCurrencyCode) ? '118px' : '110px'}
      >
        <AmountSection>
          <CryptoCol span={15}>
            <CardInput>
              <Row><Col><Label fontSize={destinationAmount}>You receive</Label></Col></Row>
              {
                // destinationAmount > 0 &&
                <SourceSkeleton active loading={!selectedDestinationCurrency.currencyCode} paragraph={{ rows: 0 }} style={{ paddingTop: '5px' }}>
                  <AmountWrapper>
                    <SearchField
                      type='text'
                      bordered={false}
                      placeholder={''}
                      disabled={true}
                      value={destinationAmount > 0 ? cryptoPrecision(destinationAmount, exchangeRate, destinationCurrencyCode) : ''}
                    />
                  </AmountWrapper>
                </SourceSkeleton>
              }
            </CardInput>
          </CryptoCol>
          <CurrencyCol span={9}>
            <DestinationSkeleton active loading={!selectedDestinationCurrency.currencyCode} paragraph={{ rows: 0 }} style={{ paddingTop: '25px' }}>
              <DestinationCurrencyField>
                <InputWrapper>
                  <DrawerDropdown
                    selectedCurrency={selectedDestinationCurrency}
                    header={buySellFlow === 'BUY' ? 'Select a Cryptocurrency' : 'Select Your Currency'}
                    inputPlaceholder={buySellFlow === 'BUY' ? 'Select Cryptocurrency' : 'Select a Currency'}
                    inputError={isDestinationInputError}
                    inputErrorMsg={buySellFlow === 'BUY' ? 'Please select a cryptocurrency' : 'Please select a currency'}
                    searchPlaceholder={buySellFlow === 'BUY' ? 'Search for a token' : 'Search for a currency'}
                    searchListName={buySellFlow === 'BUY' ? 'Cryptocurrencies' : 'Currencies'}
                    content={destinationCurrencyData}
                    selectedValue={selectedDestinationCurrency ? `${selectedDestinationCurrency.currencyCode}` : ''}
                    disabled={lockDestinationCurrencyCode}
                    category={buySellFlow === 'BUY' ? 'crypto' : 'fiat'}
                    styling={{
                      borderRadius: '2px',
                      height: '38px',
                      lineHeight: '38px',
                      padding: '0px 11px',
                      fontSize: '16px'
                    }}
                    onSelect={(c) => {
                      if (buySellFlow === 'BUY') {
                        lastCryptoCurrencyState(c)
                      }
                      setSelectedDestinationCurrency(c)
                      dispatch(setSelectedDestination(c))
                      dispatch(setDestinationCurrencyCode(c.currencyCode))
                      resetWalletAddressEntered(false)
                      mixPanel({
                        eventName: buySellFlow === 'BUY' ? 'buy_details_token_selection' : 'sell_details_fiat_currency_selection',
                        buySellFlow: buySellFlow,
                        payload: {
                          [buySellFlow === 'BUY' ? 'cryptoCurrencyCode' : 'fiatCurrencyCode']: buySellFlow === 'BUY' ? c.cryptoCurrencyCode : c.fiatCurrencyCode
                        }
                      })
                    }}
                    onOpen={() => {
                      mixPanel({
                        eventName: buySellFlow === 'BUY' ? 'buy_details_token_drawer_open' : 'sell_details_fiat_currency_drawer_open',
                        buySellFlow: buySellFlow
                      })
                    }}
                    onClose={() => {
                      mixPanel({
                        eventName: buySellFlow === 'BUY' ? 'buy_details_token_drawer_back_button' : 'sell_details_fiat_currency_drawer_back_button',
                        buySellFlow: buySellFlow
                      })
                    }}
                    onNetworkDropDown={(e) => { }}
                    onNetworkSelection={(e) => { }}
                    cryptoChain={cryptoChain}
                  />
                </InputWrapper>
              </DestinationCurrencyField>
            </DestinationSkeleton>
          </CurrencyCol>
        </AmountSection>
        {
          buySellFlow === 'BUY' &&
          // <SourceSkeleton  active loading={selectedDestinationCurrency.currencyCode} paragraph={{rows: 0}}>
            <Row style={{ textAlign: 'right', margin: '5px 2px', color: theme.colors.labelColor }}>
              <Col span={24}>
                {
                  selectedDestinationCurrency.currencyCode &&
                  <>
                    {/* <Avatar style={{height: '18px', width: '18px', display: 'inline-block', marginTop: '-2px', marginRight: '7px'}} src={selectedDestinationCurrency?.symbolImageUrl} /> */}
                    on {selectedDestinationCurrency.chainName}
                  </>
                }
              </Col>
            </Row>
          // </SourceSkeleton>
        }
      </Container>
      {
        false > 0 &&
        <Row>
          <ExchangeCol span={15} height={buySellFlow === 'BUY' ? true : false}>{buySellFlow === 'BUY' && `1 ${selectedDestinationCurrency.name} = ${exchangeRateAmount} ${sourceCurrencyCode}`}</ExchangeCol>
        </Row>
      }
      <QuotesWrapper>
        <WizardQuoteWrapperNew
          serviceProviderDetails={serviceProvider}
          isInitialLoad={isInitialLoad}
          sourceErrorMsg={sourceErrorMsg}
          topServiceProviderList={topServiceProviderList}
          onModalError={onModalError}
          isWalletAddressEntered={isWalletAddressEntered}
          resetWalletAddressEntered={resetWalletAddressEntered}
          isCountryChange={isCountryChange}
          walletAddress={userWalletAddress}
        />
      </QuotesWrapper>


    </>
  )
}

export default WizardDestinationWrapper
