export const providerCryptoCurrency = (urlParams, destinaionCurrencyData, sourceCurrencyCode, buySellFlow) => {
  const result = {}

  const lockCryptoCurrencyCode = buySellFlow === 'SELL' ? urlParams?.sourceCurrencyCodeLocked : urlParams?.destinationCurrencyCodeLocked
  const cryptoCurrencyCode = buySellFlow === 'SELL' ? urlParams?.sourceCurrencyCode : urlParams?.destinationCurrencyCode

  let SPPSupportedCryptoCurrencyCode = destinaionCurrencyData?.find(currency => currency.currencyCode === lockCryptoCurrencyCode)?.currencyCode
  result.initial = SPPSupportedCryptoCurrencyCode

  SPPSupportedCryptoCurrencyCode = SPPSupportedCryptoCurrencyCode || destinaionCurrencyData?.find(currency => currency.currencyCode === cryptoCurrencyCode)?.currencyCode
  || destinaionCurrencyData?.find(currency => currency.currencyCode === sourceCurrencyCode)?.currencyCode
  || destinaionCurrencyData?.find(currency => currency.currencyCode === ('BTC' || 'ETH'))?.currencyCode
  || (destinaionCurrencyData?.length ? destinaionCurrencyData?.[0]?.currencyCode : '')
  result.final = SPPSupportedCryptoCurrencyCode

  return result
}

export default providerCryptoCurrency
