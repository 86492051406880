import React from 'react'
import { PROVIDERS } from 'utils/constants'
import { useSelector } from 'react-redux'

import Error from 'components/core/error'
import Landing from 'components/core/landing'

import BanxaProvider from 'components/providers/banxa'
import BtcdirectProvider from 'components/providers/btcdirect'
import MoonpayProvider from 'components/providers/moonpay'
import RampProvider from 'components/providers/ramp'
import SimplexProvider from 'components/providers/simplex'
import TransakProvider from 'components/providers/transak'
import WyreProvider from 'components/providers/wyre'
import XanpoolProvider from 'components/providers/xanpool'
import OnRampMoneyProvider from 'components/providers/onRampMoney'
import SardineProvider from 'components/providers/sardine'
import BinanceProvider from 'components/providers/binance'
import TransfiProvider from 'components/providers/transfi'
import UnlimitProvider from 'components/providers/unlimit'
import PaybisProvider from 'components/providers/paybis'
import FonbnkProvider from 'components/providers/fonbnk'
import KoyweProvider from 'components/providers/koywe'
import CoinbasePayProvider from 'components/providers/coinbasepay'
import MeshProvider from 'components/providers/mesh'
import StripeProvider from 'components/providers/stripe'
import BlockChainProvider from 'components/providers/blockchain'
import BiliraProvider from 'components/providers/bilira'
import AlchemypayProvider from 'components/providers/alchemypay'
import OnMetaProvider from 'components/providers/onmeta'
import YellowCardProvider from 'components/providers/yellowcard'
import PaypalProvider from 'components/providers/paypal'
import TopperProvider from 'components/providers/topper'
import GuardarianProvider from 'components/providers/guardarian'
import SkrillCryptoProvider from 'components/providers/skrillcrypto'
import MesoProvider from 'components/providers/meso'

import Wizard from 'components/wizard/wizard'

// eslint-disable-next-line complexity
const Router = () => {
  const { displayPage, sessionData } = useSelector((state) => state.app)

  if (displayPage === 'error') {
    return <Error />
  }

  if (displayPage === 'landing') {
    return <Landing />
  }

  if (displayPage === 'wizard') {
    return <Wizard />
  }

  let serviceProvider = sessionData.sessionData.serviceProvider

  if (!sessionData || !serviceProvider) {
    return <div>No Processor Found</div>
  }

  // Display service provider handler
  switch (serviceProvider) {
    case PROVIDERS.WYRE:
      return <WyreProvider />
    case PROVIDERS.MOONPAY:
      return <MoonpayProvider />
    case PROVIDERS.RAMP:
      return <RampProvider />
    case PROVIDERS.BANXA:
      return <BanxaProvider />
    case PROVIDERS.BTCDIRECT:
      return <BtcdirectProvider />
    case PROVIDERS.XANPOOL:
      return <XanpoolProvider />
    case PROVIDERS.SIMPLEX:
      return <SimplexProvider />
    case PROVIDERS.TRANSAK:
      return <TransakProvider />
    case PROVIDERS.ONRAMPMONEY:
      return <OnRampMoneyProvider />
    case PROVIDERS.SARDINE:
      return <SardineProvider />
    case PROVIDERS.BINANCECONNECT:
      return <BinanceProvider />
    case PROVIDERS.TRANSFI:
      return <TransfiProvider />
    case PROVIDERS.UNLIMIT:
      return <UnlimitProvider />
    case PROVIDERS.PAYBIS:
      return <PaybisProvider />
    case PROVIDERS.FONBNK:
      return <FonbnkProvider />
    case PROVIDERS.KOYWE:
      return <KoyweProvider />
    case PROVIDERS.COINBASEPAY:
      return <CoinbasePayProvider />
    case PROVIDERS.MESH:
      return <MeshProvider />
    case PROVIDERS.STRIPE:
      return <StripeProvider />
    case PROVIDERS.BLOCKCHAINDOTCOM:
      return <BlockChainProvider />
    case PROVIDERS.BILIRA:
      return <BiliraProvider />
    case PROVIDERS.ALCHEMYPAY:
      return <AlchemypayProvider />
    case PROVIDERS.ONMETA:
      return <OnMetaProvider />
    case PROVIDERS.YELLOWCARD:
      return <YellowCardProvider />
    case PROVIDERS.PAYPAL:
      return <PaypalProvider />
    case PROVIDERS.TOPPER:
      return <TopperProvider />
    case PROVIDERS.GUARDARIAN:
      return <GuardarianProvider />
    case PROVIDERS.SKRILLCRYPTO:
      return <SkrillCryptoProvider />
    case PROVIDERS.MESO:
      return <MesoProvider />
    default:
      return null
  }
}

export default Router
