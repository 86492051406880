import React from 'react'
import LogoPageWrapper from 'components/ui/logo-page-wrapper'
import { useSelector } from 'react-redux'

const YellowCardProvider = () => {
  const sessionData = useSelector((state) => state.app.sessionData)

  return (
    <LogoPageWrapper>
      <iframe
        id="yellowcard-iframe"
        title="yellowcard-iframe"
        allow="accelerometer; autoplay; camera; gyroscope; payment"
        frameBorder="0"
        src={`${sessionData.sessionData.serviceProviderUrl}`}
        style={{ width: '100%', maxWidth: 430, height: '100%', minHeight: 544 }}
      >
        <p>Your browser does not support iframes.</p>
      </iframe>
    </LogoPageWrapper>
  )
}

export default YellowCardProvider
